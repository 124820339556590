var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("header", { staticClass: "top-search" }, [
        _c(
          "div",
          { staticClass: "a-input-margin" },
          [
            _c("a-input", {
              staticStyle: { width: "150px" },
              attrs: { placeholder: "请输入关键词", size: "small" },
              model: {
                value: _vm.searchParams.title,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "title", $$v)
                },
                expression: "searchParams.title"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "a-input-margin" },
          [
            _c(
              "a-select",
              {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "请选择类型", size: "small" },
                model: {
                  value: _vm.searchParams.type,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "type", $$v)
                  },
                  expression: "searchParams.type"
                }
              },
              _vm._l(_vm.type_list, function(item) {
                return _c("a-select-option", { key: item.type }, [
                  _vm._v(_vm._s(item.title))
                ])
              }),
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "a-input-margin" },
          [
            _c(
              "a-button",
              { attrs: { size: "small" }, on: { click: _vm.search } },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c(
            "a-table",
            {
              attrs: {
                loading: _vm.loading,
                pagination: _vm.pagination,
                columns: _vm.columns,
                "data-source": _vm.data,
                rowKey: function(record) {
                  return record.id
                }
              },
              on: { change: _vm.changePage },
              scopedSlots: _vm._u([
                {
                  key: "img",
                  fn: function(row) {
                    return _c("div", { staticClass: "table-imgs" }, [
                      row.imageUrls && row.imageUrls.length > 1
                        ? _c("img", {
                            attrs: { src: row.imageUrls[0], alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.previewImg(row)
                              }
                            }
                          })
                        : _c("span", [_vm._v("暂无图片")])
                    ])
                  }
                },
                {
                  key: "network",
                  fn: function(row) {
                    return _c("div", { staticClass: "table-network" }, [
                      _c("p", [
                        _vm._v("标题："),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "user-select": "all"
                            }
                          },
                          [_vm._v(_vm._s(row.title || ""))]
                        )
                      ]),
                      _c("p", [_vm._v("价格：" + _vm._s(row.price))]),
                      _c("p", [_vm._v("截拍时间：" + _vm._s(row.endTime))]),
                      _c("p", [_vm._v("其他信息：" + _vm._s(row.category))]),
                      _c("p", [_vm._v("评级公司：" + _vm._s(row.company))]),
                      _c("p", [_vm._v("评分：" + _vm._s(row.grade || "89"))]),
                      _c("p", [_vm._v("竞拍人数：" + _vm._s(row.bidnum))])
                    ])
                  }
                },
                {
                  key: "mannage",
                  fn: function(row) {
                    return _c(
                      "div",
                      {
                        staticClass: "table-network",
                        staticStyle: { "min-width": "300px" }
                      },
                      [
                        _c(
                          "a-form",
                          _vm._b({}, "a-form", _vm.layout, false),
                          [
                            _c(
                              "a-form-item",
                              { attrs: { label: "钱币名称" } },
                              [
                                _c("a-input", {
                                  attrs: { size: "small" },
                                  model: {
                                    value:
                                      row.forms.coinKindItemVersionGoodsName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row.forms,
                                        "coinKindItemVersionGoodsName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "row.forms.coinKindItemVersionGoodsName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "大币种" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    ref: "addse",
                                    attrs: {
                                      size: "small",
                                      mode: "tags",
                                      "filter-option": _vm.untils.filterOption,
                                      showSearch: "",
                                      placeholder: "请选择"
                                    },
                                    on: {
                                      search: _vm.searchData,
                                      change: function($event) {
                                        return _vm.changeSelect($event, row, 1)
                                      }
                                    },
                                    model: {
                                      value: row.forms.coinKindSid,
                                      callback: function($$v) {
                                        _vm.$set(row.forms, "coinKindSid", $$v)
                                      },
                                      expression: "row.forms.coinKindSid"
                                    }
                                  },
                                  _vm._l(_vm.currency_big_list, function(item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.id,
                                        attrs: { value: item.sid }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.dynastyName
                                              ? item.coinKindName +
                                                  "(" +
                                                  item.dynastyName +
                                                  ")"
                                              : "" + item.coinKindName
                                          )
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "小币种" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      size: "small",
                                      mode: "tags",
                                      "filter-option": _vm.untils.filterOption,
                                      showSearch: "",
                                      placeholder: "请选择"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeSelect($event, row, 2)
                                      }
                                    },
                                    model: {
                                      value: row.forms.coinKindItemSid,
                                      callback: function($$v) {
                                        _vm.$set(
                                          row.forms,
                                          "coinKindItemSid",
                                          $$v
                                        )
                                      },
                                      expression: "row.forms.coinKindItemSid"
                                    }
                                  },
                                  _vm._l(_vm.currency_small_list, function(
                                    item
                                  ) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.id,
                                        attrs: { value: item.sid }
                                      },
                                      [_vm._v(_vm._s(item.coinKindItemName))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "版别" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      size: "small",
                                      mode: "tags",
                                      placeholder: "请选择",
                                      "filter-option": _vm.untils.filterOption,
                                      showSearch: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeSelect($event, row, 3)
                                      }
                                    },
                                    model: {
                                      value: row.forms.coinKindItemVersionSid,
                                      callback: function($$v) {
                                        _vm.$set(
                                          row.forms,
                                          "coinKindItemVersionSid",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "row.forms.coinKindItemVersionSid"
                                    }
                                  },
                                  _vm._l(_vm.bottles, function(item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.id,
                                        attrs: { value: item.sid }
                                      },
                                      [_vm._v(_vm._s(item.coinKindVersionName))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "评级公司" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      size: "small",
                                      mode: "tags",
                                      placeholder: "请选择",
                                      "filter-option": _vm.untils.filterOption,
                                      showSearch: ""
                                    },
                                    model: {
                                      value:
                                        row.forms
                                          .coinKindItemVersionGoodsCompany,
                                      callback: function($$v) {
                                        _vm.$set(
                                          row.forms,
                                          "coinKindItemVersionGoodsCompany",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "row.forms.coinKindItemVersionGoodsCompany"
                                    }
                                  },
                                  _vm._l(_vm.company, function(item) {
                                    return _c(
                                      "a-select-option",
                                      { key: item, attrs: { value: item } },
                                      [_vm._v(_vm._s(item))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "证书编号" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      height: "100%",
                                      position: "relative",
                                      top: "8px"
                                    }
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { size: "small" },
                                      model: {
                                        value: row.forms.certificate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            row.forms,
                                            "certificate",
                                            $$v
                                          )
                                        },
                                        expression: "row.forms.certificate"
                                      }
                                    }),
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.coverModalImgs(row)
                                          }
                                        }
                                      },
                                      [_vm._v("查询")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "评分" } },
                              [
                                _c("a-input", {
                                  attrs: { size: "small" },
                                  model: {
                                    value:
                                      row.forms.coinKindItemVersionGoodsGrade,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row.forms,
                                        "coinKindItemVersionGoodsGrade",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "row.forms.coinKindItemVersionGoodsGrade"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "版别正面" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      height: "100%"
                                    }
                                  },
                                  [
                                    _c(
                                      "a-input",
                                      {
                                        staticStyle: { width: "150px" },
                                        attrs: {
                                          allowClear: "",
                                          size: "small"
                                        },
                                        on: {
                                          paste: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.untils.pasteImg(
                                              $event,
                                              1,
                                              _vm.parsetCallback,
                                              row.forms
                                            )
                                          }
                                        },
                                        model: {
                                          value: row.forms.front_img,
                                          callback: function($$v) {
                                            _vm.$set(
                                              row.forms,
                                              "front_img",
                                              $$v
                                            )
                                          },
                                          expression: "row.forms.front_img"
                                        }
                                      },
                                      [
                                        _c(
                                          "a-upload",
                                          {
                                            attrs: {
                                              slot: "addonBefore",
                                              name: "file",
                                              showUploadList: false,
                                              action: _vm.ip + "/file/upload"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleChange(
                                                  $event,
                                                  row.forms,
                                                  1
                                                )
                                              }
                                            },
                                            slot: "addonBefore"
                                          },
                                          [
                                            _c(
                                              "a",
                                              [
                                                _c("a-icon", {
                                                  attrs: { type: "upload" }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { margin: "0 5px" } },
                                      [_vm._v("版别背面:")]
                                    ),
                                    _c(
                                      "a-input",
                                      {
                                        staticStyle: { width: "150px" },
                                        attrs: {
                                          allowClear: "",
                                          size: "small"
                                        },
                                        on: {
                                          paste: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.untils.pasteImg(
                                              $event,
                                              2,
                                              _vm.parsetCallback,
                                              row.forms
                                            )
                                          }
                                        },
                                        model: {
                                          value: row.forms.contrary_img,
                                          callback: function($$v) {
                                            _vm.$set(
                                              row.forms,
                                              "contrary_img",
                                              $$v
                                            )
                                          },
                                          expression: "row.forms.contrary_img"
                                        }
                                      },
                                      [
                                        _c(
                                          "a-upload",
                                          {
                                            attrs: {
                                              slot: "addonBefore",
                                              name: "file",
                                              showUploadList: false,
                                              action: _vm.ip + "/file/upload"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.handleChange(
                                                  $event,
                                                  row.forms,
                                                  2
                                                )
                                              }
                                            },
                                            slot: "addonBefore"
                                          },
                                          [
                                            _c(
                                              "a",
                                              [
                                                _c("a-icon", {
                                                  attrs: { type: "upload" }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("a-form-item", { attrs: { label: "重量" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("a-input", {
                                    staticStyle: { width: "150px" },
                                    attrs: { suffix: "克", size: "small" },
                                    model: {
                                      value: row.forms.weight,
                                      callback: function($$v) {
                                        _vm.$set(row.forms, "weight", $$v)
                                      },
                                      expression: "row.forms.weight"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0 5px" } },
                                    [_vm._v("尺寸:")]
                                  ),
                                  _c("a-input", {
                                    staticStyle: { flex: "1" },
                                    attrs: { size: "small" },
                                    model: {
                                      value: row.forms.measure,
                                      callback: function($$v) {
                                        _vm.$set(row.forms, "measure", $$v)
                                      },
                                      expression: "row.forms.measure"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c(
                              "a-form-item",
                              { attrs: { label: "价格" } },
                              [
                                _c("a-input", {
                                  attrs: { size: "small" },
                                  model: {
                                    value:
                                      row.forms.coinKindItemVersionGoodsPrice,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row.forms,
                                        "coinKindItemVersionGoodsPrice",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "row.forms.coinKindItemVersionGoodsPrice"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "级别" } },
                              [
                                _c("a-input", {
                                  attrs: { size: "small" },
                                  model: {
                                    value:
                                      row.forms.coinKindItemVersionGoodsLevel,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row.forms,
                                        "coinKindItemVersionGoodsLevel",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "row.forms.coinKindItemVersionGoodsLevel"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "描述" } },
                              [
                                _c("a-input", {
                                  attrs: { size: "small" },
                                  model: {
                                    value: row.forms.goodsDesc,
                                    callback: function($$v) {
                                      _vm.$set(row.forms, "goodsDesc", $$v)
                                    },
                                    expression: "row.forms.goodsDesc"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "标签" } },
                              [
                                _vm._l(row.forms.tagList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "a-tag",
                                    {
                                      key: index,
                                      attrs: { closable: "" },
                                      on: {
                                        close: function($event) {
                                          return _vm.remove(
                                            row.forms.tagList,
                                            index
                                          )
                                        },
                                        click: function($event) {
                                          return _vm.confirmTag(
                                            row.forms.tagList,
                                            item,
                                            index
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item.coinTagName))]
                                  )
                                }),
                                _c(
                                  "a-button",
                                  {
                                    attrs: { size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmTag(
                                          row.forms.tagList,
                                          {}
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("添加")]
                                )
                              ],
                              2
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "附加属性" } },
                              _vm._l(
                                row.forms.coinKindItemVersionGoodsJson,
                                function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "space-between"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-wrap": "no-wrap",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          _vm._v("属性名:"),
                                          _c("a-input", {
                                            staticStyle: { width: "80px" },
                                            attrs: { size: "small" },
                                            model: {
                                              value: item.title,
                                              callback: function($$v) {
                                                _vm.$set(item, "title", $$v)
                                              },
                                              expression: "item.title"
                                            }
                                          }),
                                          _vm._v("属性值:"),
                                          _c("a-input", {
                                            staticStyle: { width: "80px" },
                                            attrs: { size: "small" },
                                            model: {
                                              value: item.value,
                                              callback: function($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { size: "small" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addAttrs(
                                                row.forms
                                                  .coinKindItemVersionGoodsJson
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("添加")]
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "danger",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.remove(
                                                row.forms
                                                  .coinKindItemVersionGoodsJson,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }
                },
                {
                  key: "setup",
                  fn: function(index) {
                    return _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-items": "center"
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "map-margin",
                            on: {
                              click: function($event) {
                                return _vm.add(index)
                              }
                            }
                          },
                          [_vm._v("加入")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "map-margin",
                            on: {
                              click: function($event) {
                                return _vm.copy(index)
                              }
                            }
                          },
                          [_vm._v("复制")]
                        ),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "Are you sure Scrap this item?",
                              "ok-text": "Yes",
                              "cancel-text": "No"
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.reset(index)
                              }
                            }
                          },
                          [
                            _c("a", { staticClass: "map-margin" }, [
                              _vm._v("废弃")
                            ])
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "map-margin",
                            on: {
                              click: function($event) {
                                return _vm.breakData(index)
                              }
                            }
                          },
                          [_vm._v("跳过")]
                        )
                      ],
                      1
                    )
                  }
                }
              ])
            },
            [_vm._v(" Scrap the ")]
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "标签", "ok-text": "确认", "cancel-text": "取消" },
          on: {
            cancel: function($event) {
              _vm.tagForms = {}
            },
            ok: _vm.addTag
          },
          model: {
            value: _vm.tagVisible,
            callback: function($$v) {
              _vm.tagVisible = $$v
            },
            expression: "tagVisible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "tagforms",
                attrs: { model: _vm.tagForms, rules: _vm.tagRules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "coinTagName" } },
                [
                  _c("a-input", {
                    attrs: { size: "small", placeholder: "名称" },
                    model: {
                      value: _vm.tagForms.coinTagName,
                      callback: function($$v) {
                        _vm.$set(_vm.tagForms, "coinTagName", $$v)
                      },
                      expression: "tagForms.coinTagName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签分类", prop: "coinTagCategory" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { size: "small", placeholder: "标签分类" },
                      model: {
                        value: _vm.tagForms.coinTagCategory,
                        callback: function($$v) {
                          _vm.$set(_vm.tagForms, "coinTagCategory", $$v)
                        },
                        expression: "tagForms.coinTagCategory"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("别名标签")
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("钱币性质标签")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }