<template>
    <div class="content">
        <header class="top-search">
            <div class="a-input-margin">
                <a-input v-model="searchParams.title" style="width:150px" placeholder='请输入关键词' size='small'></a-input>
            </div>

            <div class="a-input-margin">
                <a-select  v-model="searchParams.type" placeholder='请选择类型' style="width:150px" size='small' >
                    <a-select-option  v-for="item of type_list" :key="item.type">{{item.title}}</a-select-option>
                </a-select>
                
            </div>

            <div class="a-input-margin">
                <a-button @click="search" size='small'>搜索</a-button>
            </div>
        </header>
        <div class="table-content">
            <a-table @change="changePage" :loading='loading' :pagination='pagination' :columns="columns" :data-source="data" :rowKey="record=>record.id">
                <div slot='img' slot-scope="row" class="table-imgs">
                    
                    <img v-if="row.imageUrls&&row.imageUrls.length>1" @click="previewImg(row)" :src="row.imageUrls[0]" alt="">
                    <span v-else>暂无图片</span>
                </div>

                <div slot='network' slot-scope="row" class="table-network">
                    <p>标题：<span style="font-weight:bold;user-select:all;">{{row.title||''}}</span></p>
                    <p>价格：{{row.price}}</p>
                    <p>截拍时间：{{row.endTime}}</p>
                    <p>其他信息：{{row.category}}</p>
                    <p>评级公司：{{row.company}}</p>
                    <p>评分：{{row.grade||'89'}}</p>
                    <p>竞拍人数：{{row.bidnum}}</p>
                </div>

                <div slot='mannage' slot-scope="row" class="table-network" style="min-width:300px;">
                <a-form v-bind="layout">
                    <a-form-item label='钱币名称'><a-input size='small' v-model="row.forms.coinKindItemVersionGoodsName"></a-input></a-form-item>
                    <a-form-item label='大币种'>
                        <a-select size='small' mode='tags' @search='searchData' ref='addse' v-model="row.forms.coinKindSid" :filter-option="untils.filterOption" showSearch @change='changeSelect($event,row,1)' placeholder='请选择'>
                            <a-select-option v-for="item of currency_big_list" :key="item.id" :value='item.sid'>{{item.dynastyName ? `${item.coinKindName}(${item.dynastyName})` : `${item.coinKindName}`}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label='小币种'>
                        <a-select size='small' mode='tags' :filter-option="untils.filterOption" showSearch v-model="row.forms.coinKindItemSid" @change='changeSelect($event,row,2)'  placeholder='请选择'>
                            <a-select-option  v-for="item of currency_small_list" :key="item.id" :value='item.sid'>{{item.coinKindItemName}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label='版别'>
                        <a-select size='small' mode='tags' placeholder='请选择' :filter-option="untils.filterOption" showSearch v-model="row.forms.coinKindItemVersionSid" @change='changeSelect($event,row,3)'>
                            <a-select-option v-for="item of bottles" :key="item.id" :value='item.sid'>{{item.coinKindVersionName}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label='评级公司'>
                        <a-select size='small' mode='tags' placeholder='请选择' :filter-option="untils.filterOption" showSearch v-model="row.forms.coinKindItemVersionGoodsCompany">
                            <a-select-option v-for="item of company" :key="item" :value='item'>{{item}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label='证书编号'>
                        <div style="display:flex;align-items:center;height:100%;position:relative;top:8px;">
                            <a-input v-model="row.forms.certificate" size='small'></a-input>
                            <a-button type='primary' @click="coverModalImgs(row)" size='small'>查询</a-button>
                        </div>
                    </a-form-item>
                    <a-form-item label='评分'><a-input size='small' v-model="row.forms.coinKindItemVersionGoodsGrade"></a-input></a-form-item>
                    <a-form-item label='版别正面'>
                        <div style="display:flex;align-items:center;height:100%;">
                            <a-input allowClear @paste.stop.prevent="untils.pasteImg($event,1,parsetCallback,row.forms)" v-model="row.forms.front_img" size='small' style="width:150px;">
                                <a-upload
                                    slot='addonBefore'
                                    name="file"
                                    :showUploadList='false'
                                    :action="ip+'/file/upload'"
                                    @change="handleChange($event,row.forms,1)">
                                    <a> <a-icon type="upload"/></a>
                                </a-upload>
                            </a-input>
                            <span style="margin:0 5px;">版别背面:</span>
                            <a-input allowClear @paste.stop.prevent="untils.pasteImg($event,2,parsetCallback,row.forms)" v-model='row.forms.contrary_img' size='small' style="width:150px">
                                <a-upload
                                    slot='addonBefore'
                                    name="file"
                                    :showUploadList='false'
                                    :action="ip+'/file/upload'"
                                    @change="handleChange($event,row.forms,2)">
                                    <a> <a-icon type="upload"/></a>
                                </a-upload>
                            </a-input>
                        </div>
                    </a-form-item>
                    <a-form-item label='重量'>
                        <div style="display:flex;align-items:center">
                            <a-input style="width:150px" suffix="克" size='small' v-model="row.forms.weight"></a-input>
                            <span style="margin:0 5px;">尺寸:</span>
                            <a-input v-model="row.forms.measure" size='small' style="flex:1"></a-input>
                        </div>
                        
                    </a-form-item>
                    
                    <a-form-item label='价格'><a-input size='small' v-model="row.forms.coinKindItemVersionGoodsPrice"></a-input></a-form-item>
                    <a-form-item label='级别'><a-input size='small' v-model="row.forms.coinKindItemVersionGoodsLevel"></a-input></a-form-item>
                    <a-form-item label='描述'><a-input size='small' v-model="row.forms.goodsDesc"></a-input></a-form-item>
                    <a-form-item label='标签'>
                            <a-tag closable @close='remove(row.forms.tagList,index)' v-for="(item,index) in row.forms.tagList" :key="index" @click="confirmTag(row.forms.tagList,item,index)">{{item.coinTagName}}</a-tag>
                            <a-button @click="confirmTag(row.forms.tagList,{})" size='small'>添加</a-button>
                    </a-form-item>
                    <a-form-item label='附加属性'>
                        <div v-for="(item,index) of row.forms.coinKindItemVersionGoodsJson" :key="index" style="display:flex;align-items:center;justify-content:space-between;">
                            <div style="display:flex;flex-wrap:no-wrap;align-items:center;">属性名:<a-input size='small' v-model="item.title" style="width:80px;"></a-input>属性值:<a-input size='small' v-model="item.value" style="width:80px;"></a-input></div>
                            <a-button @click="addAttrs(row.forms.coinKindItemVersionGoodsJson)" size='small'>添加</a-button>
                            <a-button @click="remove(row.forms.coinKindItemVersionGoodsJson,index)" type='danger' size='small'>删除</a-button>
                        </div>
                    </a-form-item>
                </a-form>
                </div>
                Scrap the
                <div slot='setup' slot-scope="index" style="display:flex;flex-direction:column;align-items:center;">
                    <a @click="add(index)" class="map-margin" >加入</a>
                    <a class="map-margin" @click="copy(index)">复制</a>
                    <a-popconfirm
                        title="Are you sure Scrap this item?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="reset(index)"
                    >
                    <a class="map-margin">废弃</a>
                    </a-popconfirm>
                    <a class="map-margin" @click="breakData(index)">跳过</a>
                </div>
            </a-table>
        </div>
        
        <a-modal v-model="tagVisible" title="标签" ok-text="确认" cancel-text="取消" @cancel='tagForms = {}' @ok="addTag">
            <a-form-model
                ref="tagforms"
                :model="tagForms"
                :rules="tagRules"
                v-bind="layout">   
                <a-form-model-item label="名称" prop="coinTagName" >
                    <a-input size='small' placeholder='名称' v-model="tagForms.coinTagName"/>
                </a-form-model-item>

                <a-form-model-item label='标签分类' prop='coinTagCategory'>
                    <a-select size='small' placeholder='标签分类' v-model="tagForms.coinTagCategory">
                        <a-select-option :value='1'>别名标签</a-select-option>
                        <a-select-option :value='2'>钱币性质标签</a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
    data(){
        return {
            columns:[
                {title:'图片',align:'center',scopedSlots: { customRender: 'img' },width:100},
                {title:'网络数据',scopedSlots: { customRender: 'network' },width:300,},
                {title:'后台数据',scopedSlots: { customRender: 'mannage' },width:500,},
                {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},
            ],
            data:[],
            layout:{
                labelCol: { span: 5 },//标签占5份  共24份
                wrapperCol: { span:19 },
            },
            type_list:[
                {title:'微拍堂',type:1},
                {title:'古泉园地',type:2},
                {title:'聚藏天下',type:3},
                {title:'微钱币',type:4},
                {title:'钱币天堂',type:5},
                {title:'玩物得志',type:6},
                {title:'爱藏',type:7},
                {title:'收藏圈',type:8},
                {title:'7788收藏',type:9},
                {title:'赵涌在线',type:10},
            ],
            params:{page:1,limit:2,conditions:''},
            bottles:[],
            tagList:[],
            coinKindItemVersionGoodsJson:[{title:'',value:''}],
            tagForms:{},
            tagRules:{
                coinTagCategory:[{ required: true, message: 'please change', trigger: 'change' }],
                coinTagName:[{ required: true, message: 'please change', trigger: 'change' }], 
            },
            tagVisible:false,
            tagCurrent:'',
            loading:false,
            searchParams:{
                title:'',
            },
            pagination:{
                total:0,
                current:1,
                pageSize:2,
                'show-quick-jumper':true,
                showTotal:(total)=>`共${total}条`,
            },
            currency_big_list:[],
            currency_small_list:[],
            company:["公博","保粹","闻德 ","华夏","众诚详评","园地","PCGS"]
        }
    },
    computed:{
        ...mapState(['ip'])
    },
    created(){
        const {title,price} = this.$route.query
        if(title){
            this.searchParams.title = title
            this.search(1,price)
        }else{
            this.getData()
        }  
    },
    methods:{
        getData(){
            this.loading = true
            this.axios('/dq_admin/spider_coin_goods/list',{params:this.params}).then(res=>{
                this.loading = false
                const {records,total} = res.data
                this.pagination.total = total
                records.forEach(element => {
                    element.forms = {
                        coinKindItemVersionGoodsName:element.title,
                        coinKindItemVersionGoodsPrice:element.price,
                        coinKindItemVersionGoodsJson : [{title:'',value:''}],
                        coinKindSid:[],
                        coinKindItemSid:[],
                        coinKindItemVersionSid:[],
                        tagList : [],
                        front_img:'',
                        contrary_img:'',
                        measure:'',
                        certificate:'',
                        goodsDesc:'',
                    }
                });
                this.data = records
            })
        },
        add(row){
            const {endTime,forms} = row
            const json_forms = JSON.parse(JSON.stringify(forms))
            json_forms.coinKindItemVersionGoodsJson = JSON.stringify(json_forms.coinKindItemVersionGoodsJson)
            try{
                json_forms.coinKindItemVersionGoodsCompany=json_forms.coinKindItemVersionGoodsCompany[0]
                json_forms.images=row.imageUrls.join(',')
            }catch(e){
            }
            
            this.axios.post('/dq_admin/kind_item_version_goods/add',{...json_forms,coinKindItemVersionGoodsDealTime:endTime,spiderId:row.id,imageUrls:JSON.stringify(row.imageUrls)}).then(res=>{
                if(res.status == 'SUCCESS'){
                    this.$message.success(res.message)
                    this.getData()
                }
            })
        },
        copy(row){
            this.copy_text(row.webUrl)
        },
        async reset(row){
            const res = await this.axios('/dq_admin/spider_coin_goods/removeItemsById',{params:{id:row.id}})
            if(res.status == 'SUCCESS'){
                this.$message.success(res.message)
                this.getData()
            }
        },
        addTag(){
            if(typeof this.tagCurrent == 'number'){
                this.tagList.splice(this.tagCurrent,1,this.tagForms)
            }else{
                this.tagList.push(this.tagForms)
            }
            this.tagVisible = false
        },
        confirmTag(array,row,index){
            this.tagVisible = true
            this.tagForms = row
            this.tagCurrent = index
            this.tagList = array
        },
        remove(array,n){
            if(array.length == 1){return this.$message.error('默认项不可删除')}
            array.splice(n,1)
        },
        addAttrs(array){
            array.push({title:'',value:''})
        },
        copy_text(text){
            let oInput = document.createElement('input')
            oInput.value = text
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.remove()
            this.$message.success('已复制进剪切板')
        },
        search(type,price=''){
            const conditions = this.untils.setParams({...this.searchParams,state:type == 1 ? 1 : '',price:price||''})
            this.params.page = 1
            this.pagination.current = 1
            this.params = {...this.params,conditions:conditions}
            this.getData()
        },
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.page = current
            this.getData(this.params)
        },
        previewImg(row){
            if(row.imageUrls&&row.imageUrls.length>0){
                this.$previewImg({
                    list:row.imageUrls.reduce((pre,cur)=>{
                        const obj = {img_url:cur}
                        pre = pre.concat(obj)
                        return pre
                    },[]),
                    baseImgField:'img_url'
                })
            }
        },
        changeSelect(sid,row,type){
            if(sid&&sid.length>1){
                sid.length = 1
            }
            if(sid&&sid.length==1){
                if(type == 1){
                    const result = this.replaceSid(this.currency_big_list,'coinKindName',sid.join(','))
                    if(result&&result.length>0){
                        sid = [result[0].sid]
                    }
                    row.forms.coinKindSid = sid
                    this.axios('/dq_admin/kind/getItemAndVersion',{params:{sid:sid.join(',')}}).then(res=>{
                        const {coinItem,coinItemVersion} = res.data
                        this.bottles = coinItemVersion
                        this.currency_small_list = coinItem
                    })
                }else if(type == 2){
                    const result = this.replaceSid(this.currency_small_list,'coinKindItemName',sid.join(','))
                    if(result&&result.length>0){
                        sid = [result[0].sid]
                    }
                    row.forms.coinKindItemSid = sid
                    this.axios('/dq_admin/kinditem/getInnerVersionList',{params:{sid:sid.join(',')}}).then(res=>{
                        const {data} = res
                        this.bottles = data
                    })
                }else{
                    const result = this.replaceSid(this.bottles,'coinKindVersionName',sid.join(','))
                    if(result&&result.length>0){
                        sid = [result[0].sid]
                    }
                    row.forms.coinKindItemVersionSid = sid
                }
            }
        },
        breakData(row){
            this.axios('/dq_admin/spider_coin_goods/skipItemsById',{params:{id:row.id}}).then(res=>{
                if(res.status == 'SUCCESS'){
                    this.$message.success(res.message)
                    this.getData()
                }
            })
        },
        searchData(inner){
            this.axios('/dq_admin/kind/list',{params:{
                coinName: inner
            }}).then(res=>{
                if(res.status == '200'){
                    const {records} = res.data
                    this.currency_big_list = records
                }
            })
        },
        replaceSid(array,key,name){
            const result = array.filter(item=>item[key] == name)
            return result
        },
        changeCompany(e,row){
            
        },
        changeKind(sid){
            
        },

        coverModalImgs(row){
            if(!row.forms.coinKindItemVersionGoodsCompany){
                return this.$message.warning('选择评级公司')
            }
            this.axios('/dq_admin/kind_item_version_goods/getInfoByCompanyAndCode',{params:{code:row.forms.certificate,type:row.forms.coinKindItemVersionGoodsCompany.join(',')||''}}).then(resp=>{
                const {list,coinKindItemVersionGoodsGrade,weight,measure} = resp.data
                row.forms.coinKindItemVersionGoodsGrade = coinKindItemVersionGoodsGrade
                row.forms.weight = weight
                row.forms.measure = measure
                this.coverImg({
                    title:'裁切图片',
                    list:list||[
                        'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3310030543,722019725&fm=26&gp=0.jpg',
                        'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1903706955,195331234&fm=26&gp=0.jpg'
                    ],
                    then:(res)=>{
                        const {front,contrary} = res
                        row.forms.front_img = front ? front  : row.forms.front_img
                        row.forms.contrary_img = contrary ? contrary : row.forms.contrary_img
                    }
                })
            }) 
        },
        handleChange(res,form,type){
            const {response} = res.file
            const {data} = response
            if(data){
                type == 1 ? form.front_img = data : form.contrary_img = data
            }
        },
        parsetCallback(type,data,row){
            type == 1 ? row.front_img = data : row.contrary_img = data
        }
    }
}
</script>

<style lang="scss" scoped>
.content{display: flex;flex-direction: column;
    .top-search{display: flex;align-items: center;}
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.table-imgs{
    img{max-width:200px;}
}
.map-width{width:120px;}
.top-search{width:100%;height:50px;background:#fff;}
.a-input-margin{margin-left:5px;}
.ant-form-item{
    margin-bottom: 15px;
}
</style>